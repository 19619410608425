//-- copyright
// OpenProject is an open source project management software.
// Copyright (C) 2012-2023 the OpenProject GmbH
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License version 3.
//
// OpenProject is a fork of ChiliProject, which is a fork of Redmine. The copyright follows:
// Copyright (C) 2006-2013 Jean-Philippe Lang
// Copyright (C) 2010-2013 the ChiliProject Team
//
// This program is free software; you can redistribute it and/or
// modify it under the terms of the GNU General Public License
// as published by the Free Software Foundation; either version 2
// of the License, or (at your option) any later version.
//
// This program is distributed in the hope that it will be useful,
// but WITHOUT ANY WARRANTY; without even the implied warranty of
// MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
// GNU General Public License for more details.
//
// You should have received a copy of the GNU General Public License
// along with this program; if not, write to the Free Software
// Foundation, Inc., 51 Franklin Street, Fifth Floor, Boston, MA  02110-1301, USA.
//
// See COPYRIGHT and LICENSE files for more details.
//++

.op-tile-block
  $block: &
  display: grid
  grid-template-rows: repeat(minmax(200px, auto))
  grid-template-columns: auto auto
  grid-gap: 1rem

  &--tile
    border-radius: 10px
    display: grid
    grid-template: auto / 1fr auto
    grid-row-gap: 5px
    justify-items: left
    background: #f7fafc
    min-height: 150px
    padding: 1rem
    border: 1px solid var(--button--border-color)

    &:disabled
      background: #fafafa

    &:hover
      text-decoration: none
      border: 1px solid grey
      border-radius: 10px !important
      cursor: pointer

    &.-disabled
      @include button-disabled

  &--content
    display: flex
    column-gap: 0.75rem

    .form--radio-button
      margin-top: 0.75rem

  &--image
    display: block
    margin-top: auto
    margin-bottom: auto

  &--title
    padding-top: 0
    padding-bottom: 5px
    color: var(--primary-color-dark)
    display: block
    text-align: left
    font-weight: bolder
    font-size: large

  &--description
    text-align: left
    width: 90%
    font-weight: normal
