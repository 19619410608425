$user-avatar-width: 36px
$user-avatar-height: 36px
$user-avatar-medium-border-radius: 50%
$user-avatar-medium-width: 28px
$user-avatar-medium-height: 28px
$user-avatar-mini-border-radius: 50%
$user-avatar-mini-width: 20px
$user-avatar-mini-height: 20px
$user-avatar-default-bg-color: white


.op-avatar
  border-radius: var(--user-avatar-border-radius)
  width: $user-avatar-width
  // min-width was added because the avatar was getting squished as a flex-child
  min-width: $user-avatar-width
  height: $user-avatar-height
  color: white
  display: inline-block
  text-align: center
  vertical-align: middle
  cursor: inherit
  user-select: none
  background-color: $user-avatar-default-bg-color
  // Default size
  line-height: 36px
  font-size: 16px

  // Fallback avatar if no img tag gets rendered
  &--fallback
    max-width: none

  &_mini
    border-radius: $user-avatar-mini-border-radius
    width: $user-avatar-mini-width
    min-width: $user-avatar-mini-width
    height: $user-avatar-mini-height
    line-height: $user-avatar-mini-height
    font-size: 10px

  &_medium
    border-radius: $user-avatar-medium-border-radius
    width: $user-avatar-medium-width
    min-width: $user-avatar-medium-width
    height: $user-avatar-medium-height
    line-height: $user-avatar-medium-height
    font-size: 13px

  // Different border styling for placeholders
  &_placeholder-user
    border: 1px dashed
    background: none

  // Different border styling for groups
  &_group
    border: 1px solid white
    box-shadow: 4px 0px 0px -1px #cccccc
