
@mixin icon-mixin-accessibility 
  content: "\f101"

.icon-accessibility:before
  content: "\f101"


@mixin icon-mixin-accountable 
  content: "\f102"

.icon-accountable:before
  content: "\f102"


@mixin icon-mixin-activity-history 
  content: "\f103"

.icon-activity-history:before
  content: "\f103"


@mixin icon-mixin-add-attachment 
  content: "\f104"

.icon-add-attachment:before
  content: "\f104"


@mixin icon-mixin-add-link 
  content: "\f105"

.icon-add-link:before
  content: "\f105"


@mixin icon-mixin-add 
  content: "\f106"

.icon-add:before
  content: "\f106"


@mixin icon-mixin-align-center 
  content: "\f107"

.icon-align-center:before
  content: "\f107"


@mixin icon-mixin-align-justify 
  content: "\f108"

.icon-align-justify:before
  content: "\f108"


@mixin icon-mixin-align-left 
  content: "\f109"

.icon-align-left:before
  content: "\f109"


@mixin icon-mixin-align-right 
  content: "\f10a"

.icon-align-right:before
  content: "\f10a"


@mixin icon-mixin-arrow-down1 
  content: "\f10b"

.icon-arrow-down1:before
  content: "\f10b"


@mixin icon-mixin-arrow-down2 
  content: "\f10c"

.icon-arrow-down2:before
  content: "\f10c"


@mixin icon-mixin-arrow-in 
  content: "\f10d"

.icon-arrow-in:before
  content: "\f10d"


@mixin icon-mixin-arrow-left-right 
  content: "\f10e"

.icon-arrow-left-right:before
  content: "\f10e"


@mixin icon-mixin-arrow-left1 
  content: "\f10f"

.icon-arrow-left1:before
  content: "\f10f"


@mixin icon-mixin-arrow-left2 
  content: "\f110"

.icon-arrow-left2:before
  content: "\f110"


@mixin icon-mixin-arrow-left3 
  content: "\f111"

.icon-arrow-left3:before
  content: "\f111"


@mixin icon-mixin-arrow-left4 
  content: "\f112"

.icon-arrow-left4:before
  content: "\f112"


@mixin icon-mixin-arrow-out 
  content: "\f113"

.icon-arrow-out:before
  content: "\f113"


@mixin icon-mixin-arrow-right2 
  content: "\f114"

.icon-arrow-right2:before
  content: "\f114"


@mixin icon-mixin-arrow-right3 
  content: "\f115"

.icon-arrow-right3:before
  content: "\f115"


@mixin icon-mixin-arrow-right4 
  content: "\f116"

.icon-arrow-right4:before
  content: "\f116"


@mixin icon-mixin-arrow-right5 
  content: "\f117"

.icon-arrow-right5:before
  content: "\f117"


@mixin icon-mixin-arrow-right6 
  content: "\f118"

.icon-arrow-right6:before
  content: "\f118"


@mixin icon-mixin-arrow-right7 
  content: "\f119"

.icon-arrow-right7:before
  content: "\f119"


@mixin icon-mixin-arrow-thin 
  content: "\f11a"

.icon-arrow-thin:before
  content: "\f11a"


@mixin icon-mixin-arrow-up1 
  content: "\f11b"

.icon-arrow-up1:before
  content: "\f11b"


@mixin icon-mixin-arrow-up2 
  content: "\f11c"

.icon-arrow-up2:before
  content: "\f11c"


@mixin icon-mixin-assigned-to-me 
  content: "\f11d"

.icon-assigned-to-me:before
  content: "\f11d"


@mixin icon-mixin-assigned 
  content: "\f11e"

.icon-assigned:before
  content: "\f11e"


@mixin icon-mixin-attachment 
  content: "\f11f"

.icon-attachment:before
  content: "\f11f"


@mixin icon-mixin-attention 
  content: "\f120"

.icon-attention:before
  content: "\f120"


@mixin icon-mixin-back-up 
  content: "\f121"

.icon-back-up:before
  content: "\f121"


@mixin icon-mixin-backlogs 
  content: "\f122"

.icon-backlogs:before
  content: "\f122"


@mixin icon-mixin-baseline 
  content: "\f123"

.icon-baseline:before
  content: "\f123"


@mixin icon-mixin-bcf 
  content: "\f124"

.icon-bcf:before
  content: "\f124"


@mixin icon-mixin-bell 
  content: "\f125"

.icon-bell:before
  content: "\f125"


@mixin icon-mixin-billing-information 
  content: "\f126"

.icon-billing-information:before
  content: "\f126"


@mixin icon-mixin-boards 
  content: "\f127"

.icon-boards:before
  content: "\f127"


@mixin icon-mixin-bold 
  content: "\f128"

.icon-bold:before
  content: "\f128"


@mixin icon-mixin-budget 
  content: "\f129"

.icon-budget:before
  content: "\f129"


@mixin icon-mixin-bug 
  content: "\f12a"

.icon-bug:before
  content: "\f12a"


@mixin icon-mixin-calendar 
  content: "\f12b"

.icon-calendar:before
  content: "\f12b"


@mixin icon-mixin-calendar2 
  content: "\f12c"

.icon-calendar2:before
  content: "\f12c"


@mixin icon-mixin-camera 
  content: "\f12d"

.icon-camera:before
  content: "\f12d"


@mixin icon-mixin-cancel-circle 
  content: "\f12e"

.icon-cancel-circle:before
  content: "\f12e"


@mixin icon-mixin-cancel 
  content: "\f12f"

.icon-cancel:before
  content: "\f12f"


@mixin icon-mixin-cart 
  content: "\f130"

.icon-cart:before
  content: "\f130"


@mixin icon-mixin-changeset-down 
  content: "\f131"

.icon-changeset-down:before
  content: "\f131"


@mixin icon-mixin-changeset-up 
  content: "\f132"

.icon-changeset-up:before
  content: "\f132"


@mixin icon-mixin-changeset 
  content: "\f133"

.icon-changeset:before
  content: "\f133"


@mixin icon-mixin-chart1 
  content: "\f134"

.icon-chart1:before
  content: "\f134"


@mixin icon-mixin-chart2 
  content: "\f135"

.icon-chart2:before
  content: "\f135"


@mixin icon-mixin-chart3 
  content: "\f136"

.icon-chart3:before
  content: "\f136"


@mixin icon-mixin-checkmark-circle 
  content: "\f137"

.icon-checkmark-circle:before
  content: "\f137"


@mixin icon-mixin-checkmark 
  content: "\f138"

.icon-checkmark:before
  content: "\f138"


@mixin icon-mixin-clipboard 
  content: "\f139"

.icon-clipboard:before
  content: "\f139"


@mixin icon-mixin-close 
  content: "\f13a"

.icon-close:before
  content: "\f13a"


@mixin icon-mixin-code-tag 
  content: "\f13b"

.icon-code-tag:before
  content: "\f13b"


@mixin icon-mixin-color-text 
  content: "\f13c"

.icon-color-text:before
  content: "\f13c"


@mixin icon-mixin-color-underline 
  content: "\f13d"

.icon-color-underline:before
  content: "\f13d"


@mixin icon-mixin-column-left 
  content: "\f13e"

.icon-column-left:before
  content: "\f13e"


@mixin icon-mixin-column-right 
  content: "\f13f"

.icon-column-right:before
  content: "\f13f"


@mixin icon-mixin-columns 
  content: "\f140"

.icon-columns:before
  content: "\f140"


@mixin icon-mixin-compare2 
  content: "\f141"

.icon-compare2:before
  content: "\f141"


@mixin icon-mixin-concept 
  content: "\f142"

.icon-concept:before
  content: "\f142"


@mixin icon-mixin-console-light 
  content: "\f143"

.icon-console-light:before
  content: "\f143"


@mixin icon-mixin-console 
  content: "\f144"

.icon-console:before
  content: "\f144"


@mixin icon-mixin-contacts 
  content: "\f145"

.icon-contacts:before
  content: "\f145"


@mixin icon-mixin-copy 
  content: "\f146"

.icon-copy:before
  content: "\f146"


@mixin icon-mixin-cost-reports 
  content: "\f147"

.icon-cost-reports:before
  content: "\f147"


@mixin icon-mixin-cost-types 
  content: "\f148"

.icon-cost-types:before
  content: "\f148"


@mixin icon-mixin-cursor 
  content: "\f149"

.icon-cursor:before
  content: "\f149"


@mixin icon-mixin-custom-development 
  content: "\f14a"

.icon-custom-development:before
  content: "\f14a"


@mixin icon-mixin-custom-fields 
  content: "\f14b"

.icon-custom-fields:before
  content: "\f14b"


@mixin icon-mixin-cut 
  content: "\f14c"

.icon-cut:before
  content: "\f14c"


@mixin icon-mixin-date-alert 
  content: "\f14d"

.icon-date-alert:before
  content: "\f14d"


@mixin icon-mixin-date-alerts 
  content: "\f14e"

.icon-date-alerts:before
  content: "\f14e"


@mixin icon-mixin-delete-folder 
  content: "\f14f"

.icon-delete-folder:before
  content: "\f14f"


@mixin icon-mixin-delete 
  content: "\f150"

.icon-delete:before
  content: "\f150"


@mixin icon-mixin-delta-triangle 
  content: "\f151"

.icon-delta-triangle:before
  content: "\f151"


@mixin icon-mixin-dependency 
  content: "\f152"

.icon-dependency:before
  content: "\f152"


@mixin icon-mixin-design 
  content: "\f153"

.icon-design:before
  content: "\f153"


@mixin icon-mixin-double-arrow-left 
  content: "\f154"

.icon-double-arrow-left:before
  content: "\f154"


@mixin icon-mixin-double-arrow-right 
  content: "\f155"

.icon-double-arrow-right:before
  content: "\f155"


@mixin icon-mixin-download-arrow 
  content: "\f156"

.icon-download-arrow:before
  content: "\f156"


@mixin icon-mixin-download 
  content: "\f157"

.icon-download:before
  content: "\f157"


@mixin icon-mixin-drag-handle 
  content: "\f158"

.icon-drag-handle:before
  content: "\f158"


@mixin icon-mixin-dropdown-open 
  content: "\f159"

.icon-dropdown-open:before
  content: "\f159"


@mixin icon-mixin-dropdown 
  content: "\f15a"

.icon-dropdown:before
  content: "\f15a"


@mixin icon-mixin-duplicate 
  content: "\f15b"

.icon-duplicate:before
  content: "\f15b"


@mixin icon-mixin-edit 
  content: "\f15c"

.icon-edit:before
  content: "\f15c"


@mixin icon-mixin-email-alert 
  content: "\f15d"

.icon-email-alert:before
  content: "\f15d"


@mixin icon-mixin-enterprise-addons 
  content: "\f15e"

.icon-enterprise-addons:before
  content: "\f15e"


@mixin icon-mixin-enterprise 
  content: "\f15f"

.icon-enterprise:before
  content: "\f15f"


@mixin icon-mixin-enumerations 
  content: "\f160"

.icon-enumerations:before
  content: "\f160"


@mixin icon-mixin-error 
  content: "\f161"

.icon-error:before
  content: "\f161"


@mixin icon-mixin-export-atom 
  content: "\f162"

.icon-export-atom:before
  content: "\f162"


@mixin icon-mixin-export-bcf 
  content: "\f163"

.icon-export-bcf:before
  content: "\f163"


@mixin icon-mixin-export-csv 
  content: "\f164"

.icon-export-csv:before
  content: "\f164"


@mixin icon-mixin-export-pdf-descr 
  content: "\f165"

.icon-export-pdf-descr:before
  content: "\f165"


@mixin icon-mixin-export-pdf-with-descriptions 
  content: "\f166"

.icon-export-pdf-with-descriptions:before
  content: "\f166"


@mixin icon-mixin-export-pdf 
  content: "\f167"

.icon-export-pdf:before
  content: "\f167"


@mixin icon-mixin-export-xls-descr 
  content: "\f168"

.icon-export-xls-descr:before
  content: "\f168"


@mixin icon-mixin-export-xls-with-descriptions 
  content: "\f169"

.icon-export-xls-with-descriptions:before
  content: "\f169"


@mixin icon-mixin-export-xls-with-relations 
  content: "\f16a"

.icon-export-xls-with-relations:before
  content: "\f16a"


@mixin icon-mixin-export-xls 
  content: "\f16b"

.icon-export-xls:before
  content: "\f16b"


@mixin icon-mixin-export 
  content: "\f16c"

.icon-export:before
  content: "\f16c"


@mixin icon-mixin-external-link 
  content: "\f16d"

.icon-external-link:before
  content: "\f16d"


@mixin icon-mixin-faq 
  content: "\f16e"

.icon-faq:before
  content: "\f16e"


@mixin icon-mixin-file-doc 
  content: "\f16f"

.icon-file-doc:before
  content: "\f16f"


@mixin icon-mixin-file-form 
  content: "\f170"

.icon-file-form:before
  content: "\f170"


@mixin icon-mixin-file-presentation 
  content: "\f171"

.icon-file-presentation:before
  content: "\f171"


@mixin icon-mixin-file-sheet 
  content: "\f172"

.icon-file-sheet:before
  content: "\f172"


@mixin icon-mixin-file-text 
  content: "\f173"

.icon-file-text:before
  content: "\f173"


@mixin icon-mixin-filter 
  content: "\f174"

.icon-filter:before
  content: "\f174"


@mixin icon-mixin-flag 
  content: "\f175"

.icon-flag:before
  content: "\f175"


@mixin icon-mixin-folder-add 
  content: "\f176"

.icon-folder-add:before
  content: "\f176"


@mixin icon-mixin-folder-locked 
  content: "\f177"

.icon-folder-locked:before
  content: "\f177"


@mixin icon-mixin-folder-open 
  content: "\f178"

.icon-folder-open:before
  content: "\f178"


@mixin icon-mixin-folder-remove 
  content: "\f179"

.icon-folder-remove:before
  content: "\f179"


@mixin icon-mixin-folder 
  content: "\f17a"

.icon-folder:before
  content: "\f17a"


@mixin icon-mixin-forums 
  content: "\f17b"

.icon-forums:before
  content: "\f17b"


@mixin icon-mixin-from-fullscreen 
  content: "\f17c"

.icon-from-fullscreen:before
  content: "\f17c"


@mixin icon-mixin-getting-started 
  content: "\f17d"

.icon-getting-started:before
  content: "\f17d"


@mixin icon-mixin-glossar 
  content: "\f17e"

.icon-glossar:before
  content: "\f17e"


@mixin icon-mixin-google-plus 
  content: "\f17f"

.icon-google-plus:before
  content: "\f17f"


@mixin icon-mixin-group-by 
  content: "\f180"

.icon-group-by:before
  content: "\f180"


@mixin icon-mixin-group 
  content: "\f181"

.icon-group:before
  content: "\f181"


@mixin icon-mixin-hamburger 
  content: "\f182"

.icon-hamburger:before
  content: "\f182"


@mixin icon-mixin-headline1 
  content: "\f183"

.icon-headline1:before
  content: "\f183"


@mixin icon-mixin-headline2 
  content: "\f184"

.icon-headline2:before
  content: "\f184"


@mixin icon-mixin-headline3 
  content: "\f185"

.icon-headline3:before
  content: "\f185"


@mixin icon-mixin-headset 
  content: "\f186"

.icon-headset:before
  content: "\f186"


@mixin icon-mixin-help 
  content: "\f187"

.icon-help:before
  content: "\f187"


@mixin icon-mixin-help1 
  content: "\f188"

.icon-help1:before
  content: "\f188"


@mixin icon-mixin-help2 
  content: "\f189"

.icon-help2:before
  content: "\f189"


@mixin icon-mixin-hierarchy 
  content: "\f18a"

.icon-hierarchy:before
  content: "\f18a"


@mixin icon-mixin-home 
  content: "\f18b"

.icon-home:before
  content: "\f18b"


@mixin icon-mixin-hosting 
  content: "\f18c"

.icon-hosting:before
  content: "\f18c"


@mixin icon-mixin-ifc 
  content: "\f18d"

.icon-ifc:before
  content: "\f18d"


@mixin icon-mixin-image1 
  content: "\f18e"

.icon-image1:before
  content: "\f18e"


@mixin icon-mixin-image2 
  content: "\f18f"

.icon-image2:before
  content: "\f18f"


@mixin icon-mixin-import 
  content: "\f190"

.icon-import:before
  content: "\f190"


@mixin icon-mixin-inbox 
  content: "\f191"

.icon-inbox:before
  content: "\f191"


@mixin icon-mixin-info1 
  content: "\f192"

.icon-info1:before
  content: "\f192"


@mixin icon-mixin-info2 
  content: "\f193"

.icon-info2:before
  content: "\f193"


@mixin icon-mixin-input-disabled 
  content: "\f194"

.icon-input-disabled:before
  content: "\f194"


@mixin icon-mixin-installation-services 
  content: "\f195"

.icon-installation-services:before
  content: "\f195"


@mixin icon-mixin-italic 
  content: "\f196"

.icon-italic:before
  content: "\f196"


@mixin icon-mixin-key 
  content: "\f197"

.icon-key:before
  content: "\f197"


@mixin icon-mixin-link 
  content: "\f198"

.icon-link:before
  content: "\f198"


@mixin icon-mixin-loading1 
  content: "\f199"

.icon-loading1:before
  content: "\f199"


@mixin icon-mixin-loading2 
  content: "\f19a"

.icon-loading2:before
  content: "\f19a"


@mixin icon-mixin-location 
  content: "\f19b"

.icon-location:before
  content: "\f19b"


@mixin icon-mixin-locked 
  content: "\f19c"

.icon-locked:before
  content: "\f19c"


@mixin icon-mixin-logout 
  content: "\f19d"

.icon-logout:before
  content: "\f19d"


@mixin icon-mixin-mail1 
  content: "\f19e"

.icon-mail1:before
  content: "\f19e"


@mixin icon-mixin-mail2 
  content: "\f19f"

.icon-mail2:before
  content: "\f19f"


@mixin icon-mixin-maintenance-support 
  content: "\f1a0"

.icon-maintenance-support:before
  content: "\f1a0"


@mixin icon-mixin-mark-all-read 
  content: "\f1a1"

.icon-mark-all-read:before
  content: "\f1a1"


@mixin icon-mixin-mark-read 
  content: "\f1a2"

.icon-mark-read:before
  content: "\f1a2"


@mixin icon-mixin-medal 
  content: "\f1a3"

.icon-medal:before
  content: "\f1a3"


@mixin icon-mixin-meetings 
  content: "\f1a4"

.icon-meetings:before
  content: "\f1a4"


@mixin icon-mixin-mention 
  content: "\f1a5"

.icon-mention:before
  content: "\f1a5"


@mixin icon-mixin-menu 
  content: "\f1a6"

.icon-menu:before
  content: "\f1a6"


@mixin icon-mixin-merge-branch 
  content: "\f1a7"

.icon-merge-branch:before
  content: "\f1a7"


@mixin icon-mixin-microphone 
  content: "\f1a8"

.icon-microphone:before
  content: "\f1a8"


@mixin icon-mixin-milestone 
  content: "\f1a9"

.icon-milestone:before
  content: "\f1a9"


@mixin icon-mixin-minus1 
  content: "\f1aa"

.icon-minus1:before
  content: "\f1aa"


@mixin icon-mixin-minus2 
  content: "\f1ab"

.icon-minus2:before
  content: "\f1ab"


@mixin icon-mixin-mobile 
  content: "\f1ac"

.icon-mobile:before
  content: "\f1ac"


@mixin icon-mixin-modules 
  content: "\f1ad"

.icon-modules:before
  content: "\f1ad"


@mixin icon-mixin-more 
  content: "\f1ae"

.icon-more:before
  content: "\f1ae"


@mixin icon-mixin-move 
  content: "\f1af"

.icon-move:before
  content: "\f1af"


@mixin icon-mixin-movie 
  content: "\f1b0"

.icon-movie:before
  content: "\f1b0"


@mixin icon-mixin-music 
  content: "\f1b1"

.icon-music:before
  content: "\f1b1"


@mixin icon-mixin-new-planning-element 
  content: "\f1b2"

.icon-new-planning-element:before
  content: "\f1b2"


@mixin icon-mixin-news 
  content: "\f1b3"

.icon-news:before
  content: "\f1b3"


@mixin icon-mixin-nextcloud-circle 
  content: "\f1b4"

.icon-nextcloud-circle:before
  content: "\f1b4"


@mixin icon-mixin-nextcloud 
  content: "\f1b5"

.icon-nextcloud:before
  content: "\f1b5"


@mixin icon-mixin-no-hierarchy 
  content: "\f1b6"

.icon-no-hierarchy:before
  content: "\f1b6"


@mixin icon-mixin-no-zen-mode 
  content: "\f1b7"

.icon-no-zen-mode:before
  content: "\f1b7"


@mixin icon-mixin-not-supported 
  content: "\f1b8"

.icon-not-supported:before
  content: "\f1b8"


@mixin icon-mixin-notes 
  content: "\f1b9"

.icon-notes:before
  content: "\f1b9"


@mixin icon-mixin-openid 
  content: "\f1ba"

.icon-openid:before
  content: "\f1ba"


@mixin icon-mixin-openproject 
  content: "\f1bb"

.icon-openproject:before
  content: "\f1bb"


@mixin icon-mixin-ordered-list 
  content: "\f1bc"

.icon-ordered-list:before
  content: "\f1bc"


@mixin icon-mixin-outline 
  content: "\f1bd"

.icon-outline:before
  content: "\f1bd"


@mixin icon-mixin-paragraph-left 
  content: "\f1be"

.icon-paragraph-left:before
  content: "\f1be"


@mixin icon-mixin-paragraph-right 
  content: "\f1bf"

.icon-paragraph-right:before
  content: "\f1bf"


@mixin icon-mixin-paragraph 
  content: "\f1c0"

.icon-paragraph:before
  content: "\f1c0"


@mixin icon-mixin-payment-history 
  content: "\f1c1"

.icon-payment-history:before
  content: "\f1c1"


@mixin icon-mixin-phone 
  content: "\f1c2"

.icon-phone:before
  content: "\f1c2"


@mixin icon-mixin-pin 
  content: "\f1c3"

.icon-pin:before
  content: "\f1c3"


@mixin icon-mixin-play 
  content: "\f1c4"

.icon-play:before
  content: "\f1c4"


@mixin icon-mixin-plugins 
  content: "\f1c5"

.icon-plugins:before
  content: "\f1c5"


@mixin icon-mixin-plus 
  content: "\f1c6"

.icon-plus:before
  content: "\f1c6"


@mixin icon-mixin-pre 
  content: "\f1c7"

.icon-pre:before
  content: "\f1c7"


@mixin icon-mixin-presentation 
  content: "\f1c8"

.icon-presentation:before
  content: "\f1c8"


@mixin icon-mixin-preview 
  content: "\f1c9"

.icon-preview:before
  content: "\f1c9"


@mixin icon-mixin-print 
  content: "\f1ca"

.icon-print:before
  content: "\f1ca"


@mixin icon-mixin-priority 
  content: "\f1cb"

.icon-priority:before
  content: "\f1cb"


@mixin icon-mixin-project-types 
  content: "\f1cc"

.icon-project-types:before
  content: "\f1cc"


@mixin icon-mixin-projects 
  content: "\f1cd"

.icon-projects:before
  content: "\f1cd"


@mixin icon-mixin-publish 
  content: "\f1ce"

.icon-publish:before
  content: "\f1ce"


@mixin icon-mixin-pulldown-up 
  content: "\f1cf"

.icon-pulldown-up:before
  content: "\f1cf"


@mixin icon-mixin-pulldown 
  content: "\f1d0"

.icon-pulldown:before
  content: "\f1d0"


@mixin icon-mixin-quote 
  content: "\f1d1"

.icon-quote:before
  content: "\f1d1"


@mixin icon-mixin-quote2 
  content: "\f1d2"

.icon-quote2:before
  content: "\f1d2"


@mixin icon-mixin-redo 
  content: "\f1d3"

.icon-redo:before
  content: "\f1d3"


@mixin icon-mixin-relation-follows 
  content: "\f1d4"

.icon-relation-follows:before
  content: "\f1d4"


@mixin icon-mixin-relation-new-child 
  content: "\f1d5"

.icon-relation-new-child:before
  content: "\f1d5"


@mixin icon-mixin-relation-precedes 
  content: "\f1d6"

.icon-relation-precedes:before
  content: "\f1d6"


@mixin icon-mixin-relations 
  content: "\f1d7"

.icon-relations:before
  content: "\f1d7"


@mixin icon-mixin-reload 
  content: "\f1d8"

.icon-reload:before
  content: "\f1d8"


@mixin icon-mixin-reminder 
  content: "\f1d9"

.icon-reminder:before
  content: "\f1d9"


@mixin icon-mixin-remove-link 
  content: "\f1da"

.icon-remove-link:before
  content: "\f1da"


@mixin icon-mixin-remove 
  content: "\f1db"

.icon-remove:before
  content: "\f1db"


@mixin icon-mixin-rename 
  content: "\f1dc"

.icon-rename:before
  content: "\f1dc"


@mixin icon-mixin-reported-by-me 
  content: "\f1dd"

.icon-reported-by-me:before
  content: "\f1dd"


@mixin icon-mixin-resizer-bottom-right 
  content: "\f1de"

.icon-resizer-bottom-right:before
  content: "\f1de"


@mixin icon-mixin-resizer-vertical-lines 
  content: "\f1df"

.icon-resizer-vertical-lines:before
  content: "\f1df"


@mixin icon-mixin-roadmap 
  content: "\f1e0"

.icon-roadmap:before
  content: "\f1e0"


@mixin icon-mixin-rss 
  content: "\f1e1"

.icon-rss:before
  content: "\f1e1"


@mixin icon-mixin-rubber 
  content: "\f1e2"

.icon-rubber:before
  content: "\f1e2"


@mixin icon-mixin-save 
  content: "\f1e3"

.icon-save:before
  content: "\f1e3"


@mixin icon-mixin-search 
  content: "\f1e4"

.icon-search:before
  content: "\f1e4"


@mixin icon-mixin-select-all 
  content: "\f1e5"

.icon-select-all:before
  content: "\f1e5"


@mixin icon-mixin-send-mail 
  content: "\f1e6"

.icon-send-mail:before
  content: "\f1e6"


@mixin icon-mixin-server-key 
  content: "\f1e7"

.icon-server-key:before
  content: "\f1e7"


@mixin icon-mixin-settings 
  content: "\f1e8"

.icon-settings:before
  content: "\f1e8"


@mixin icon-mixin-settings2 
  content: "\f1e9"

.icon-settings2:before
  content: "\f1e9"


@mixin icon-mixin-settings3 
  content: "\f1ea"

.icon-settings3:before
  content: "\f1ea"


@mixin icon-mixin-settings4 
  content: "\f1eb"

.icon-settings4:before
  content: "\f1eb"


@mixin icon-mixin-shortcuts 
  content: "\f1ec"

.icon-shortcuts:before
  content: "\f1ec"


@mixin icon-mixin-show-all-projects 
  content: "\f1ed"

.icon-show-all-projects:before
  content: "\f1ed"


@mixin icon-mixin-show-more-horizontal 
  content: "\f1ee"

.icon-show-more-horizontal:before
  content: "\f1ee"


@mixin icon-mixin-show-more 
  content: "\f1ef"

.icon-show-more:before
  content: "\f1ef"


@mixin icon-mixin-slack 
  content: "\f1f0"

.icon-slack:before
  content: "\f1f0"


@mixin icon-mixin-sort-ascending 
  content: "\f1f1"

.icon-sort-ascending:before
  content: "\f1f1"


@mixin icon-mixin-sort-by 
  content: "\f1f2"

.icon-sort-by:before
  content: "\f1f2"


@mixin icon-mixin-sort-descending 
  content: "\f1f3"

.icon-sort-descending:before
  content: "\f1f3"


@mixin icon-mixin-sort-down 
  content: "\f1f4"

.icon-sort-down:before
  content: "\f1f4"


@mixin icon-mixin-sort-up 
  content: "\f1f5"

.icon-sort-up:before
  content: "\f1f5"


@mixin icon-mixin-square 
  content: "\f1f6"

.icon-square:before
  content: "\f1f6"


@mixin icon-mixin-star 
  content: "\f1f7"

.icon-star:before
  content: "\f1f7"


@mixin icon-mixin-status-reporting 
  content: "\f1f8"

.icon-status-reporting:before
  content: "\f1f8"


@mixin icon-mixin-status 
  content: "\f1f9"

.icon-status:before
  content: "\f1f9"


@mixin icon-mixin-strike-through 
  content: "\f1fa"

.icon-strike-through:before
  content: "\f1fa"


@mixin icon-mixin-team-planner 
  content: "\f1fb"

.icon-team-planner:before
  content: "\f1fb"


@mixin icon-mixin-text 
  content: "\f1fc"

.icon-text:before
  content: "\f1fc"


@mixin icon-mixin-ticket-checked 
  content: "\f1fd"

.icon-ticket-checked:before
  content: "\f1fd"


@mixin icon-mixin-ticket-down 
  content: "\f1fe"

.icon-ticket-down:before
  content: "\f1fe"


@mixin icon-mixin-ticket-edit 
  content: "\f1ff"

.icon-ticket-edit:before
  content: "\f1ff"


@mixin icon-mixin-ticket-minus 
  content: "\f200"

.icon-ticket-minus:before
  content: "\f200"


@mixin icon-mixin-ticket-note 
  content: "\f201"

.icon-ticket-note:before
  content: "\f201"


@mixin icon-mixin-ticket 
  content: "\f202"

.icon-ticket:before
  content: "\f202"


@mixin icon-mixin-time-tracking-running 
  content: "\f203"

.icon-time-tracking-running:before
  content: "\f203"


@mixin icon-mixin-time-tracking-start 
  content: "\f204"

.icon-time-tracking-start:before
  content: "\f204"


@mixin icon-mixin-time-tracking-stop 
  content: "\f205"

.icon-time-tracking-stop:before
  content: "\f205"


@mixin icon-mixin-time 
  content: "\f206"

.icon-time:before
  content: "\f206"


@mixin icon-mixin-to-fullscreen 
  content: "\f207"

.icon-to-fullscreen:before
  content: "\f207"


@mixin icon-mixin-training-consulting 
  content: "\f208"

.icon-training-consulting:before
  content: "\f208"


@mixin icon-mixin-two-factor-authentication 
  content: "\f209"

.icon-two-factor-authentication:before
  content: "\f209"


@mixin icon-mixin-types 
  content: "\f20a"

.icon-types:before
  content: "\f20a"


@mixin icon-mixin-underline 
  content: "\f20b"

.icon-underline:before
  content: "\f20b"


@mixin icon-mixin-undo 
  content: "\f20c"

.icon-undo:before
  content: "\f20c"


@mixin icon-mixin-unit 
  content: "\f20d"

.icon-unit:before
  content: "\f20d"


@mixin icon-mixin-unlocked 
  content: "\f20e"

.icon-unlocked:before
  content: "\f20e"


@mixin icon-mixin-unordered-list 
  content: "\f20f"

.icon-unordered-list:before
  content: "\f20f"


@mixin icon-mixin-unwatched 
  content: "\f210"

.icon-unwatched:before
  content: "\f210"


@mixin icon-mixin-upload-arrow 
  content: "\f211"

.icon-upload-arrow:before
  content: "\f211"


@mixin icon-mixin-upload 
  content: "\f212"

.icon-upload:before
  content: "\f212"


@mixin icon-mixin-user-minus 
  content: "\f213"

.icon-user-minus:before
  content: "\f213"


@mixin icon-mixin-user-plus 
  content: "\f214"

.icon-user-plus:before
  content: "\f214"


@mixin icon-mixin-user 
  content: "\f215"

.icon-user:before
  content: "\f215"


@mixin icon-mixin-view-card 
  content: "\f216"

.icon-view-card:before
  content: "\f216"


@mixin icon-mixin-view-fullscreen 
  content: "\f217"

.icon-view-fullscreen:before
  content: "\f217"


@mixin icon-mixin-view-list 
  content: "\f218"

.icon-view-list:before
  content: "\f218"


@mixin icon-mixin-view-model 
  content: "\f219"

.icon-view-model:before
  content: "\f219"


@mixin icon-mixin-view-split-viewer-table 
  content: "\f21a"

.icon-view-split-viewer-table:before
  content: "\f21a"


@mixin icon-mixin-view-split 
  content: "\f21b"

.icon-view-split:before
  content: "\f21b"


@mixin icon-mixin-view-split2 
  content: "\f21c"

.icon-view-split2:before
  content: "\f21c"


@mixin icon-mixin-view-timeline 
  content: "\f21d"

.icon-view-timeline:before
  content: "\f21d"


@mixin icon-mixin-warning 
  content: "\f21e"

.icon-warning:before
  content: "\f21e"


@mixin icon-mixin-watched 
  content: "\f21f"

.icon-watched:before
  content: "\f21f"


@mixin icon-mixin-watching 
  content: "\f220"

.icon-watching:before
  content: "\f220"


@mixin icon-mixin-wiki-edit 
  content: "\f221"

.icon-wiki-edit:before
  content: "\f221"


@mixin icon-mixin-wiki 
  content: "\f222"

.icon-wiki:before
  content: "\f222"


@mixin icon-mixin-wiki2 
  content: "\f223"

.icon-wiki2:before
  content: "\f223"


@mixin icon-mixin-work-packages 
  content: "\f224"

.icon-work-packages:before
  content: "\f224"


@mixin icon-mixin-workflow 
  content: "\f225"

.icon-workflow:before
  content: "\f225"


@mixin icon-mixin-yes 
  content: "\f226"

.icon-yes:before
  content: "\f226"


@mixin icon-mixin-zen-mode 
  content: "\f227"

.icon-zen-mode:before
  content: "\f227"


@mixin icon-mixin-zoom-auto 
  content: "\f228"

.icon-zoom-auto:before
  content: "\f228"


@mixin icon-mixin-zoom-in 
  content: "\f229"

.icon-zoom-in:before
  content: "\f229"


@mixin icon-mixin-zoom-out 
  content: "\f22a"

.icon-zoom-out:before
  content: "\f22a"

