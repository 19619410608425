@import 'form-field.children'

.spot-form-field
  display: flex
  flex-direction: column

  // This is a fix for the formly-forms, which render the wrapper around a non-visible input
  &:empty
    display: none

  &--label-wrap
    display: flex
    flex-direction: column
    margin: 0
    font-weight: normal

  &--label
    display: flex
    flex-direction: row
    align-items: center
    margin-bottom: $spot-spacing-0_25

    @include spot-body-small($weight: bold)
    color: $spot-color-basic-gray-1

    &-indicator
      color: $spot-color-basic-black

  &--help-text
    padding-left: $spot-spacing-0_25

  &--actions
    @include spot-body-small
    display: flex
    flex-direction: row
    flex-wrap: wrap

    > *
      margin-right: $spot-spacing-0_5

  &--input
    margin-bottom: $spot-spacing-0_25
    display: -webkit-flex /* Safari */     
    display: flex

    > *
      flex-grow: 1

  &--description
    @include spot-caption
    font-style: italic
    margin-bottom: $spot-spacing-0_25
    color: $spot-color-basic-gray-2

  &--errors
    display: flex
    flex-direction: column

  &--error
    @include spot-caption
    color: $spot-color-danger-dark
    margin-bottom: $spot-spacing-0_25

  &--label,
  &--description,
  &--errors
    &:empty
      display: none
